import React, { useState } from 'react';
import 'semantic-ui-css/semantic.min.css';
import { Accordion, AccordionContent, AccordionTitle, Container, Header, Icon, Image, List, ListHeader, ListItem } from 'semantic-ui-react';
import './App.css';
import { Link } from 'react-router-dom';

const getGooglePlayService = async () => {
    if (!'getDigitalGoodsService' in window) {
        return false
    }
    try {
        const service = await window.getDigitalGoodsService('https://play.google.com/billing')
        return true
    } catch (error) {
        return false
    }
}

const isMobile = () => {
    const userAgent = window.navigator?.userAgent
    const userAgentData = window.navigator?.userAgentData
    if (userAgentData) {
        return userAgentData.mobile
    }
    if (userAgent) {
        const patterns = [
            /iphone/i,
            /ipad/i,
            /android/i
        ]
        if (patterns.filter(p => p.test(userAgent)).length > 0) {
            return true
        }
    }
    return false
}

const About = props => {
    const [showDebug, setShowDebug] = useState(false)
    const [googlePlaySupported, setGooglePlaySupported] = useState('?')

    // getGooglePlayService().then(r => setGooglePlaySupported(r ? 'yes' : 'no'))
    
    return (
        <Container fluid>
            <Image src='/image/navscout-logo-512.png' />
            {/* &nbsp; */}
            {/* <a href='javascript:window.location.reload()'>Update</a> */}
            <h1>Information</h1>
            <Link to='/about/privacy'>Privacy Policy</Link>
            <br/>
            <Link to='/about/terms-of-service'>Terms of Service</Link>
            <Accordion>
                <AccordionTitle index={0} active={showDebug} onClick={() => setShowDebug(!showDebug)}>
                    <Icon name='dropdown' />
                    App Info
                </AccordionTitle>
                <AccordionContent active={showDebug}>
                    <List>
                        <ListItem><ListHeader>Version</ListHeader>{process.env.REACT_APP_VERSION || 'Unknown'}</ListItem>
                        <ListItem><ListHeader>Platform</ListHeader>{process.env.REACT_APP_PLATFORM || 'Unknown'}</ListItem>
                        <ListItem><ListHeader>User Agent</ListHeader>{window.navigator?.userAgent}</ListItem>
                        {/* <ListItem><ListHeader>Google Play Supported?</ListHeader>{googlePlaySupported}</ListItem> */}
                        <ListItem><ListHeader>Platform</ListHeader>{window.navigator?.userAgentData?.platform}</ListItem>
                        <ListItem><ListHeader>Mobile</ListHeader>{isMobile() ? 'Yes' : 'No'}</ListItem>
                        <ListItem><ListHeader>Brands</ListHeader>{window.navigator?.userAgentData?.brands?.map(b => b.brand)?.reduce((a,b) => a + ", " + b)}</ListItem>
                        <ListItem><ListHeader></ListHeader></ListItem>
                        <ListItem><ListHeader></ListHeader></ListItem>
                    </List>
                </AccordionContent>
            </Accordion>
            {showDebug}
            <h1>Social</h1>
            <Link style={{textDecoration: 'none'}} to='https://www.twitter.com/navscout/'><Icon size='big' name='twitter' /></Link>
            <Link style={{textDecoration: 'none'}} to='https://www.instagram.com/trynavscout/'><Icon size='big' name='instagram' /></Link>
            <Link style={{textDecoration: 'none'}} to='https://www.facebook.com/navscout/'><Icon size='big' name='facebook' /></Link>
            <Link style={{textDecoration: 'none'}} to='https://www.youtube.com/@navscout'><Icon size='big' name='youtube' /></Link>
        </Container>
    )
}

export default About;