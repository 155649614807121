import React from 'react';
import { Card, CardGroup, Icon, Menu, Popup } from 'semantic-ui-react';

const BASE_URL = 'https://www.navscout.com'

const ContextMenuItem = props => {
  // return <Menu.Item><a style={{textDecoration: 'none'}} href={props.link}><Icon name={props.icon} />{props.content}</a></Menu.Item>
  return <Card
    style={{width:'5em', textAlign: 'center', textDecoration: 'none', textAlign: 'center' }}
    href={props.link}
    header={<Icon size='large' circular color='blue' inverted name={props.icon} />}
    extra={<span style={{color:'black'}}>{props.content}</span>}
    />
}

const SubjectContext = props => {
    const s = props.subject;
    const exclusions = props.exclude

    const menuItems = []

    const addMenuItem = a => {
      const label = a?.props?.content
      const exclude = exclusions !== null && exclusions !== undefined && exclusions.indexOf(label) !== -1
      if (!exclude) {
        menuItems.push(a)
      }
    }
    
    addMenuItem(
      <ContextMenuItem icon='eye' link={subjectLink(s)} content='View' />    
    )

    if (s?.id && s?.type) {
      addMenuItem(
        <ContextMenuItem icon='chat' link={`/chat?${s?.type?.toLowerCase()}=${s?.id}`} content='Discuss' />    
      )
    }

    if (s?.type === 'PLACE') {
        addMenuItem(
            <ContextMenuItem icon='car' link={'/drive/new?destination='+s.label} content='Drive' />    
        )
        addMenuItem(
            <ContextMenuItem icon='cloud' link={subjectLink(s) + '/weather'} content='Weather' />    
        )
        addMenuItem(
          <ContextMenuItem icon='map' link={'/place?subjectId=' + s.id} content='Map' />    
      )
        addMenuItem(
          <ContextMenuItem icon='file alternate outline' link={`/report?subjectId=${s.id}`} content='Reports' />    
        )
        addMenuItem(
          <ContextMenuItem icon='images outline' link={`/gallery?subjectId=${s.id}`} content='Gallery' />    
        )
        if (props.account?.roles?.includes("REPORT")) {
          addMenuItem(
            <ContextMenuItem icon='upload' link={`/gallery/upload?subjectId=${s.id}`} content='Upload Media' />
          )
        }
    }

    if (s?.type === 'PLACE' || s?.type === 'RECORD') {
      addMenuItem(
        <ContextMenuItem icon='share square' link={`https://twitter.com/intent/tweet?text=${encodeURIComponent(s.label)}&url=${encodeURIComponent(BASE_URL + subjectLink(s))}`} content='Share' />
      )
    }

    if (s?.type === 'RECORD' && props.account?.roles?.includes("POWER")) {
      addMenuItem(
        <ContextMenuItem icon='file alternate outline' link={`${process.env.REACT_APP_REST_URI}/record/${s.id}`} content='Data' />
      )
    }

    if (props.open) {
      return (
        <CardGroup vertical>{menuItems}</CardGroup>
      )
    }

    return (
        <span>
            <Popup
                basic
                trigger={<span key={s.id} className='link'>{s.label}</span>}
                on='click'
                >
                    <Menu
                        secondary
                        vertical
                        >
                         {menuItems} 
                    </Menu>
                </Popup>
        </span>
    )
}

const subjectLink = s => {
    if (s === null || s.type === null)  {
      return null
    }
    if (s.type === 'PLACE') {
      return `/place/${s.id}`
    } else if (s.type === 'RECORD') {
      return `/report/${s.id}`
    } else if (s.type === 'TRAVEL') {
      return `/drive/${s.id}`
    }
  }

export default SubjectContext;