import React, { useState } from 'react';
import { Button, Card, CardGroup, Container, Divider, Form, FormField, FormGroup, Grid, GridColumn, GridRow, Image, Input, Message } from 'semantic-ui-react';
import Hideable from './Hideable';
import { Link, useNavigate } from 'react-router-dom';
import TravelForm from './TravelForm';

const Home = props => {
  document.title = `navscout`;
  const [dismissUpdateNotification, setDismissUpdateNotification] = useState(false);
  const navigate = useNavigate();

  return (
    <Container>
      <h1>navscout</h1>
      <p style={{fontSize: '1.5em'}}>
      Get a weather forecast for your drive.
      </p>
      <TravelForm 
            basic={true} 
            client={props.client} 
            account={props.account} 
            onSave={travel => {navigate(`/drive/${travel.id}`)}}
            />
      <Divider />
      <p>
            <Image style={{margin: 'auto'}} size='large' src='/image/map.png' />
      </p>
      <Hideable hidden={!window.notifyNewUpdate || dismissUpdateNotification}>
            <Message onDismiss={() => {
                  window.notifyNewUpdate = false;
                  setDismissUpdateNotification(true)
            }}>
                  A new update is available, close the application to update it.
            </Message>
      </Hideable>
      <h2>All Tools</h2>
      <CardGroup>
            <Card
                  image='/image/drive.jpeg'
                  header='Drive'
                  description='Get an assessment of the conditions for an upcoming trip.'
                  href='/drive/new'
            />
            <Card
                  image='/image/place.jpeg'
                  header='Places'
                  description='navscout provides travel assesments for cities across the globe.'
                  href='/place'
            />
            <Card
                  image='/image/chat.jpeg'
                  header='Chat'
                  description='Get live travel help from the navscout virtual assistant.'
                  href='/chat'
            />
      </CardGroup>
      <Divider hidden horizontal />
      <Grid columns={3} divided>
            <GridColumn>
                  <GridRow>About</GridRow>
                  <GridRow>
                        <Link to='/about/privacy'>Privacy Policy</Link><br/>
                        <Link to='/about/terms-of-service'>Terms of Service</Link>
                  </GridRow>
            </GridColumn>
            <GridColumn>
                  <GridRow>Contact</GridRow>
                  <GridRow>
                        <a href='mailto:contact@navscout.com'>email</a><br />
                  </GridRow>
            </GridColumn>
            <GridColumn>
                  <GridRow>Social</GridRow>
                  <GridRow>
                        <Link style={{textDecoration: 'none'}} to='https://www.twitter.com/navscout/'>X</Link><br/>
                        <Link style={{textDecoration: 'none'}} to='https://www.instagram.com/trynavscout/'>Instagram</Link><br/>
                        <Link style={{textDecoration: 'none'}} to='https://www.facebook.com/navscout/'>Facebook</Link><br/>
                        <Link style={{textDecoration: 'none'}} to='https://www.youtube.com/@navscout'>Youtube</Link><br/>
                  </GridRow>
            </GridColumn>
      </Grid>
    </Container>
  )
}


export default Home;